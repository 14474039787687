.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin: 5px;
  padding: 30px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 10px;
  height: 150px;
  font-family: "Poppins", sans-serif;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 10px;
      color: rgb(160, 160, 160);
    }

    .counter {
      font-size: 20px;
      font-weight: 300;
    }

    .link {
      width: max-content;
      font-size: 10px;
      border-bottom: 1px solid gray;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }

    .icon {
      font-size: 18px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
    }
  }
}

@media (max-width: 600px) {
  .widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: 150px;
    font-family: "Poppins", sans-serif;
    margin: 2%;

    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-weight: bold;
        font-size: 12px;
        color: rgb(160, 160, 160);
      }

      .counter {
        font-size: 25px;
        font-weight: bold;
      }

      .link {
        width: max-content;
        font-size: 12px;
        border-bottom: 1px solid gray;
      }

      .percentage {
        display: flex;
        align-items: center;
        font-size: 14px;

        &.positive {
          color: green;
        }
        &.negative {
          color: red;
        }
      }

      .icon {
        font-size: 18px;
        padding: 5px;
        border-radius: 5px;
        align-self: flex-end;
      }
    }
  }
}
