.custom-header-style {
  text-align: center;
  padding: 5px;
  font-size: 12px;
  /* text-align: center !important; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  color: #333;
  cursor: pointer;
  font-weight: bold;
}

/* header.css */
.header {
  /* style untuk header ketika layar lebih besar dari 600px */
  background-color: #091322;
  color: #808080;
  text-align: center;
  padding: 20px;
  flex: 0 0 auto;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

/* body.css */
.body {
  /* style untuk body ketika layar lebih besar dari 600px */
  background-color: white;
  color: black;
  padding: 20px;
  flex: 1 1 auto;
}
.sr-only{
  visibility: hidden;
}

.footer {
  /* style untuk body ketika layar lebih besar dari 600px */
  background-color: #091322;
  color: white;
  text-align: center;
  padding: 15px;
  flex: 0 0 auto;
  font-size: 10px;
  bottom: 0;
  width: 100%;
}

.app-container {
  margin: 2%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Import font */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:700,400,400italic");
/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navigasi_bar {
  font-family: "Poppins", sans-serif;
  background-color: #ad241b;
  place-items: center;
  height: auto;
  padding: 0.5em;
  position: sticky;
  top: 0px;
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; */
}

nav {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: 2em;
  margin-top: 15px;
}
ul {
  list-style: none;
  display: flex;
  gap: 2em;
  margin-left: -2%;
}
.linkkuh {
  position: relative;
  display: inline-block;
  list-style-type: none;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.linkkuh::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  scale: 0 1;
  transform-origin: right;
  transition: scale 0.25s;
}

.linkkuh:hover::before {
  scale: 1;
  transform-origin: left;
}

.body-part-satu {
  display: flex;
}

.body-pembuka {
  font-family: "Poppins", sans-serif;
  width: 70%;
  margin: 1%;
  margin-left: 1.5em;
}

.body-pembuka > span {
  font-size: 12px;
  text-align: justify;
}

.body-pencarian {
  width: 50%;
  margin: 1%;
  margin-right: 5%;
  font-family: "Poppins", sans-serif;
}

.form {
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.pendaftaran-judul {
  text-align: center;
  font-size: 15px;
  margin: 1em;
  font-weight: bolder;
}

#perloginan > label {
  margin-top: 1em;
  font-size: 14px;
}

input {
  display: block;
  width: 100%;
  padding: 3px;
  font-size: 8px;
  border-radius: 3px;
}

input::placeholder {
  font-size: 8px;
  font-style: oblique;
  font-family: "Poppins", sans-serif;
}
label {
  display: block;
  font-size: 10px;
  margin-bottom: 8px;
  font-weight: bold;
}

.tombol {
  width: 100%;
  padding: 10px;
  background-color: #ad241b;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 10px;
  font-size: 10px;
}

.pendaftaran-pengajuan {
  margin-top: 10%;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

button {
  display: block;
  width: 100%;
  font-size: 8px;
  background-color: #ad241b;
  position: relative;
  display: inline-block;
  list-style-type: none;
  color: white;
  text-decoration: none;
  padding: 7px;
}
.button-pengajuan {
  margin-top: 3%;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
}

.judul-pembuka {
  margin-bottom: 10px;
}

.body-pembuka > span {
  font-size: 14px;
  text-align: justify;
}

.head-hasil {
  font-size: 20px;
  text-align: center;
}
span {
  font-size: 12px;
}

.label-hasil {
  font-size: 15px;
}

.link-penambahan {
  position: relative;
  display: inline-block;
  list-style-type: none;
  color: white;
  text-decoration: none;
  background-color: #ad241b;
  padding: 3px;
  border-radius: 2px;
}

.link-penambahan::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  scale: 0 1;
  transform-origin: right;
  transition: scale 0.25s;
}
.link-penambahan:hover::before {
  scale: 1;
  transform-origin: left;
}

.tabel-pengguna {
  margin: 2%;
}

.perkembangan {
  background-color: #808080;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
}

.perkembangann {
  padding: 5px;
  border-radius: 6px;
  text-align: center;
}

.hasil-perkembangan {
  color: black;
  text-align: center;
  font-size: large;
  margin-top: 10px;
}

.hasil-perkembangan {
  color: white;
  text-align: center;
  font-size: large;
  margin-top: 10px;
}

.gambar-bukti {
  text-align: center;
}

.note {
  font-size: 10px;
  color: red;
}

/* 
warna pallete

#091322
#AD241B
#F2F2F2 */

.burgernih {
  width: 20px;
  margin-left: 5%;
}

.kotak-informasi {
  text-align: center;
  padding: 30px;
  background-color: #1b6fad;
}

.informasi-kontak {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.baris-kontak {
  display: flex;
  gap: 0.5rem;
}

.kolom-kontak {
  margin-top: 10px;
  aspect-ratio: 2/1.3;
  width: 20%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.kolom-kontak > label {
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-align: center;
  justify-content: center;
}

.kotak-informasi>h4{
  color: white;
}

.kolom-kontak > li {
  font-size: 10px;
  font-weight: bold;
  margin-top: 5px;
  align-items: center;
  color: white;
  list-style: none;
}
.kolom-kontak>img{
  margin-top: 10px;
  filter: hue-rotate(900deg);
}

/* .kolom-kontak > label .kolom-kontak > span {
  display: inline-block;
  vertical-align: top;
  margin-top: -5px;
  padding-top: 5px;
} */

.sistem {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-left: 2%;
}



@media (max-width: 600px) {
  .header {
    /* style untuk header ketika layar kurang dari atau sama dengan 600px */
    background-color: #091322;
    font-size: smaller;
    flex: 0 0 auto;
  }

  .body {
    /* style untuk body ketika layar kurang dari atau sama dengan 600px */
    background-color: #f2f2f2;
    font-size: smaller;
    flex: 1 1 auto;
  }

  .footer {
    /* style untuk body ketika layar kurang dari atau sama dengan 600px */
    background-color: gray;
    font-size: 8px;
    flex: 0 0 auto;
  }

  /* Import font */
  @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
  /* General styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .navigasi_bar {
    font-family: "Poppins", sans-serif;
    background-color: #ad241b;
    place-items: center;
    height: 70px;
  }

  .n-team{
    height: 80px;
  }

  .n-admin{
    height: 70px;
  }

  nav {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  ul {
    list-style: none;
    display: flex;
    gap: 10px;
    margin-left: -14%;
    
  }

  .linkkuh {
    position: relative;
    display: inline-block;
    font-size: 10px;
    list-style-type: none;
    color: white;
    text-decoration: none;
  }

  .dash{
    font-size: 8px;
  }
  .linkkuh::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    scale: 0 1;
    transform-origin: right;
    transition: scale 0.25s;
  }
  .linkkuh:hover::before {
    scale: 1;
    transform-origin: left;
    list-style-type: none;
  }

  .body-part-satu {
    display: flex;
    flex-direction: column;
  }

  .body-pembuka {
    font-family: "Poppins", sans-serif;
    font-size: small;
    text-align: justify;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: -0.3%;
  }

  .body-pencarian {
    width: 100%;
    justify-content: center;
    margin-top: 5%;
    align-items: center;
  }

  .judul-pembuka {
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }

  span {
    text-align: left;
  }

  .pengecekan {
    font-size: 13px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: block;
  }

  .sistem {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }

  input {
    display: block;
    width: 100%;
    padding: 5px;
    font-size: 8px;
    border-radius: 6px;
  }
  label {
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .form {
    justify-content: center;
    align-items: center;
    display: block;
  }

  .tombol {
    background-color: #ad241b;
  }
  .label-hasil {
    font-size: 12px;
  }

  .head-hasil {
    font-size: 14px;
  }
  .burgernih {
    width: 20px;
    margin-left: 5%;
  }

  .note {
    font-size: 9px;
    color: red;
  }

  .pendaftaran-judul {
    text-align: center;
    font-size: 13px;
  }

  .kotak-informasi > h4 {
    font-size: 10px;
  }

  .baris-kontak {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .kolom-kontak {
    width: 30%;
    text-align: center;
  }

  .kolom-kontak > label {
    /* display: block; */
    font-size: 6px;
    text-align: center;
    margin-bottom: 5px;
  }

  .kolom-kontak > li {
    font-size: 6px;
    text-align: center;
    list-style: none;
    margin-top: 2px;
  }

  .kolom-kontak > img {
    margin-top: 10px;
    width: 15px;
    height: 15px;
  }
  #perloginan > label {
    margin-top: 1em;
    font-size: 11px;
  }
}
