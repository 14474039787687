

/* styling for datalist options */
datalist option {
  padding: 5px;
  cursor: pointer;
}


.form-pengajuan {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /* max-width: 600px; */
  width: 96%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.form-group {
  flex: 1 1 100%;
  margin-bottom: 20px;
}
.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}
.form-group textarea {
  min-height: 200px;
}

.form_pertama {
  width: 100%;
}

.form_kedua {
  width: 100%;
}

.judul-pengajuan{
  font-size: 18px;
  margin-left: 1.5em;
  margin-bottom: 1em;
  color: #808080;
}

.penjelasan {
  font-size: 12px;
  margin-left: 4.3%;
  font-family: Arial, sans-serif;
}

select {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border-radius: 5px;
  border: 1px solid #ccc;
  appearance: none;
  background-color: #fff;
}

option {
  background-color: #fff;
  color: #000;
}

option[disabled] {
  background-color: #ddd;
  color: #888;
}

label {
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #444;
  display: block;
  margin-bottom: 10px;
}

.note{
  font-family: Arial, sans-serif;
  font-style: italic;
  font-size: 8px;
}

/* .aju{
  padding-left: 0px;
  padding-right: 0px;
} */

@media (max-width: 600px) {
  .judul-pengajuan{
    font-size: 14px;
    margin-left: 4.3%;
    color: #808080;
  }

  .form-pengajuan {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    /* max-width: 600px; */
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }

  label {
    font-size: 13px;
    font-family: Arial, sans-serif;
    color: #444;
    display: block;
    margin-bottom: 10px;
  }

  
}