.catatan>p{
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.catatan{
  font-family: "Poppins", sans-serif;
}
/* 
  warna pallete
  
  #091322
  #AD241B
  #F2F2F2 */

@media (max-width: 600px) {
  .body-pembuka>h5{
    font-size: 14px;
    font-weight: bold;
    text-align: justify;
  }

  .body-pembuka>span{
    font-size: 10px;
  }

  .keterangan-pengajuan>span{
    font-size: 10px;
  }
}
