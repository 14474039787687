.row > * {
  width: 20%;
}

.react-bootstrap-table-pagination {
  /* justify-content: space-around; */
}
.col-lg-6 {
  flex: 0 0 auto;
  width: 10%;
  left: 0;
  flex-direction: row;
  justify-content: space-around;
  /* margin-right: 40%; */
}

.react-bootstrap-table-page-btns-ul {
  gap: 0em !important;
}

@media (max-width: 600px) {
  .row > * {
    width: 25% !important;
  }

  .react-bootstrap-table-pagination {
    /* justify-content: flex-start; */
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 10%;
    /* left: 0; */
    /* margin-right: 40%; */
  }
  .page-item:not(:first-child) .page-link {
    /* margin-left: -10px; */
  }
}

.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.search {
  align-items: center;
  display: flex;
}

.year {
  align-items: center;
  display: flex;
}
